import { MatterType } from "@/helpers/types";
import React, { FunctionComponent, useState, useMemo } from "react";
import dynamic from "next/dynamic";
import { useDevice } from "@/helpers/deviceContext";

const MobileListItem = dynamic(() => import("./MobileListItem"));
const DesktopListItem = dynamic(() => import("./DesktopListItem"));
const DesktopSpecialProject = dynamic(() => import("./DesktopSpecialProject"));
import { isSpecialProject } from "./helpers";
import Banner from "@/components/Banner";
import { date } from "@/helpers/date";

type PropsType = {
  matters: MatterType[];
  withContentBlocks?: boolean;
  showBanner?: boolean;
  bannerSlug?: string;
  additionalBannerSlug?: string;
  bannerInterval?: number;
  showPeriod?: boolean;
  initialMattersLength?: number;
  showLiveTitles?: boolean;
};

const MattersList: FunctionComponent<PropsType> = ({
  matters,
  withContentBlocks,
  bannerSlug,
  additionalBannerSlug,
  bannerInterval = 9,
  showPeriod = true,
  initialMattersLength = 0,
  showLiveTitles = false,
}) => {
  const { isMobile } = useDevice();

  function definePeriod(el: MatterType): string {
    const firstDate = new Date(matters[0].published_at_in_timezone.iso);
    const lastDate = new Date(
      matters[matters.length - 1].published_at_in_timezone.iso
    );
    const daysDiffer =
      (firstDate.getTime() - lastDate.getTime()) / (1000 * 3600 * 24);
    const currentYear = new Date().getFullYear();
    const year = new Date(el.published_at_in_timezone.iso).getFullYear();

    if (daysDiffer > 365) {
      return `${year}`;
    }
    if (daysDiffer > 13) {
      const month = el.published_at_in_timezone.month;
      return year === currentYear ? month : `${month} ${year}`;
    } else {
      // el.published_at_in_timezone.date - it's wrong due cache
      // const date = el.published_at_in_timezone.date;
      const d = date(el.published_at_in_timezone.iso).replace(/,.*/, "");
      // return year === currentYear ?  date : `${date}`;
      return d;
    }
  }

  function periodVisible(el: MatterType, i: number): boolean {
    const prevMatter = matters[i - 1];
    if (!prevMatter) return true;
    return definePeriod(el) !== definePeriod(prevMatter);
  }

  const [isMainpageFeed3] = useState(
    Boolean(additionalBannerSlug) &&
      Boolean(initialMattersLength) &&
      matters.length <= initialMattersLength
  );

  const uniqueMatters = useMemo(() => {
    const uniqueIds: number[] = [];
    const uniqueArr: MatterType[] = [];

    const filtredMatters = matters.filter((el) => !!el?.id);

    for (const matter of filtredMatters) {
      if (!uniqueIds.includes(matter.id)) {
        uniqueIds.push(matter.id);
        uniqueArr.push(matter);
      }
    }
    return uniqueArr;
  }, [matters]);

  return (
    <div className="mx-auto max-w-[826px]">
      {uniqueMatters.map((el, i) => {
        const DesktopComponent = isSpecialProject(el)
          ? DesktopSpecialProject
          : DesktopListItem;
        const Component = isMobile ? MobileListItem : DesktopComponent;
        const isBannerShown = bannerSlug && i !== 0 && i % bannerInterval === 0;
        return (
          <React.Fragment key={i}>
            {showPeriod && periodVisible(el, i) && (
              <div className="font-bold text-[22px] mb-[24px] leading-relaxed">
                {definePeriod(el)}
              </div>
            )}
            <Component
              showLiveTitle={showLiveTitles}
              matter={el}
              withContentBlocks={withContentBlocks}
            />
            {Boolean(isMainpageFeed3 && i === 2) && (
              <Banner
                slug={additionalBannerSlug as string}
                className="mb-6 w-full"
              />
            )}
            {isBannerShown && (
              <Banner slug={bannerSlug} className="mb-6 w-full" />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(MattersList);
