import { useToplineReducer } from "@/components/Topline/toplineContext";
import { useEffect } from "react";
import { ImageType } from "./types";

export default function useToplineTransparenty(
  image?: ImageType | boolean
): void {
  const [{ isTransparent }, dispatch] = useToplineReducer();

  useEffect(() => {
    image
      ? dispatch({ type: "SET_TRANSPARENT", payload: true })
      : dispatch({ type: "SET_TRANSPARENT", payload: false });
  }, []);
}
