import { Component, createRef, ReactNode } from "react";

type PropsType = {
  loading: boolean;
  // any function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLoad: (...args: any[]) => any;
};

// functional component doesnt work well in this case
export default class InfScroll extends Component<PropsType> {
  el = createRef<HTMLDivElement>();
  observer: IntersectionObserver | null = null;

  componentDidMount(): void {
    const cb = (e: IntersectionObserverEntry[]) => {
      if (e[0].isIntersecting && !this.props.loading) {
        this.props.onLoad();
      }
    };
    this.observer =
      "IntersectionObserver" in window
        ? new IntersectionObserver(cb, {
          rootMargin: "400px",
        })
        : null;
    if (this.el.current) {
      this.observer?.observe(this.el.current);
    }
  }

  componentWillUnmount(): void {
    this.observer?.disconnect();
  }

  render(): ReactNode {
    return <div ref={this.el} />;
  }
}
