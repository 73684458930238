import { format, parseISO } from "date-fns";
import { ru } from "date-fns/locale";

export const date = (
  dateStr: string,
  options?: {
    updateLabel?: boolean;
    showYear?: boolean;
    isSameDay?: boolean;
    dontShowToday?: boolean;
  }
): string => {
  if (!dateStr) return "";
  try {
    const date = new Date(dateStr);
    const now = new Date();

    const showYear = options?.showYear;
    if (showYear) return format(date, "d MMMM yyyy, HH:mm", { locale: ru });

    let isToday = false;
    let isYesterday = false;
    if (
      date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth()
    ) {
      isToday = date.getDate() === now.getDate();
      isYesterday = date.getDate() === now.getDate() - 1;
    }
    const updateLabel = options?.updateLabel;

    if (updateLabel)
      return format(
        date,
        `Обновлено d MMMM yyyy, HH:mm`,
        { locale: ru }
      );

    // Обычная дата
    return format(
      date,
      `d MMMM${date.getFullYear() === now.getFullYear() ? "" : " yyyy"
      }, HH:mm`,
      { locale: ru }
    );
  } catch (error) {
    return "";
  }
};

/** Метод переводит дату формата ISO в GMT ( 2023-12-08T19:22:00.000+06:00 → Fri, 8 Dec 2023 16:22:00 GMT )*/
export function convertIsoToGMT(isoDate: string): string {
  const dateObj = parseISO(isoDate);
  return format(dateObj, "EEE, d MMM yyyy HH:mm:ss 'GMT'");
}

export function getToday(): string {
  return new Date().toISOString().split('T')[0].toString();
}

export function getDateWithOffset(days: number): string {
  const date = new Date();
  date.setDate(date.getDate() + days);

  // Format the date to yyyy-mm-dd
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
