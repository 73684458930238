import { format } from "date-fns";
import { ru } from "date-fns/locale";

export default function withDateToken(str: string): string {
  const tokenRegExp = new RegExp("{date:.+}", "gi");

  const newStr = str.replace(tokenRegExp, (finded) => {
    const form = finded.slice(6, -1);

    try {
      return format(new Date(), form, { locale: ru });
    } catch (error) {
      return finded;
    }
  });

  return newStr;
}
