import { useEffect, useState, useCallback } from "react";
import api from "@/helpers/api";
import error from "./error";
import { MatterType } from "./types";

type PropsType<T = MatterType> = {
  initialMatters?: T[];
  params?: { [key: string]: unknown };
  endpoint?: string;
  initialPage?: number;
};

type ReturnType<T = MatterType> = [
  { matters: T[]; loading: boolean; allLoaded: boolean },
  (reset?: boolean) => Promise<void>
];

export default function useMatters<T = MatterType>({
  initialMatters = [],
  params = {},
  endpoint = "matters",
  initialPage = 2,
}: PropsType<T>): ReturnType<T> {
  const [matters, setMatters] = useState(initialMatters);
  const [allLoaded, setAllLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(initialPage);

  const fetchMatters = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get<T[] | { matters: T[] }>(endpoint, {
        params: { ...params, page },
      });
      const items = "matters" in data ? data.matters : data;

      setMatters((prevMatters) => [...prevMatters, ...items]);
      if (items.length === 0) {
        setAllLoaded(true);
      } else {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (e) {
      error(e);
    } finally {
      setLoading(false);
    }
  }, [endpoint, params, page]);

  const getMatters = useCallback(
    async (reset = false) => {
      if (reset) {
        setMatters([]);
        setAllLoaded(false);
        setPage(1);
      }
      await fetchMatters();
    },
    [fetchMatters]
  );

  useEffect(() => {
    if (matters.length === 0 && !allLoaded && !loading) {
      void getMatters();
    }
  }, [getMatters, matters.length, allLoaded, loading]);

  return [{ matters, loading, allLoaded }, getMatters];
}
